@import "CarouselComponent";
@import "../../../../branding/uztelecom/app-uztelecom-defenition";

:global :local(carousel) {
    @image-size: 120px;
    @circle-color: @karaoke-uztelecom-color-divider;

    .carousel-new();

    & > .content > .item {
        background: white;
        padding: 9px;
        margin: 9px;
        border-radius: 8px;
        position: relative;

        & > a {
            text-decoration: none;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            width: @image-size;
            height: @image-size;
            border: 2px solid @circle-color;
            border-radius: 50%;
        }

        &:nth-child(odd):before {
            top: 4px;
            left: 4px;
        }
        &:nth-child(even):before {
            top: 14px;
            left: 14px;
        }

        & > .cover {
            & > .description {
                position: unset;
                background: transparent;
                color: black;
                text-align: center;
                text-decoration: none;

                & > .title, & > .author {
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & > .title {
                    font-weight: unset;
                    line-height: 18px;
                }

                & > .author {
                    display: none;
                }
            }
        }
    }
}