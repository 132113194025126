@import "../../../../branding/uztelecom/app-uztelecom-defenition";
@import "KarplayerComponent_tpl_v2";

.karplayer :global {

  .karplayer-impl();

  .karaoke-uztelecom-font-regular();

  .player-song-copyright > * {
    color: @karaoke-uztelecom-color-gray;
  }

  .player-content {
    .karaoke-uztelecom-font-regular();

    .karplayer .kar-progress .kar-progress-begin, .karplayer .kar-progress .kar-progress-end {
      .karaoke-uztelecom-font-small();
    }
  }

  .control-block {
    color: @karaoke-uztelecom-color-dark;
  }

  .kar-text {
    color: @karaoke-uztelecom-color-gray;

    // загрузили - приводим в начальное состояние
    &.ready {
      // пропеваемый блок
      .karaokeBlock.speakingBlock {
        span[data-begin].speaking {
          color: @karaoke-uztelecom-color-blue;
        }

        span[data-begin].speaking.finish {
          color: @karaoke-uztelecom-color-blue;
        }
      }
    }
  }

  .kar-progress {
    .progress {
      background: @karaoke-uztelecom-color-gray-light;

      .kar-progress-bar.progress-bar {
        background-color: @karaoke-uztelecom-color-blue;
      }
    }

    .kar-progress-begin, .kar-progress-end {
      .karaoke-uztelecom-font-regular();
      color: @karaoke-uztelecom-color-dark;
    }
  }

  .control-block.mobile {
    color: @karaoke-uztelecom-color-gray;

    .category {
      .karaoke-uztelecom-font-small();
      color: @karaoke-uztelecom-color-blue;
    }

    .artist, .song {
      color: @karaoke-uztelecom-color-blue;
    }

    .artist {
      .karaoke-uztelecom-font-large();

      &:after {
        .karaoke-uztelecom-font-large();
      }
    }

    .kar-button-main {
      .btn-primary {
        background-color: transparent;
      }

      width: image-width("./uztelecom/player_next.png");
      height: image-height("./uztelecom/player_next.png");

      .stop {
        background-image: url("./uztelecom/player_play.png");
      }

      .pause {
        background-image: url("./uztelecom/player_play.png");
      }

      .play {
        background-image: url("./uztelecom/player_stop.png");
      }

      .load {
        background-image: url("./uztelecom/player_wait.png");
      }
    }

    .play-next.btn, .play-next.btn:hover {
      width: image-width("./uztelecom/player_play.png");
      height: image-height("./uztelecom/player_play.png");
      background-image: url("./uztelecom/player_next.png");
    }

    .play-next.play-end, a.play-next.play-end:hover {
      background-image: url("./uztelecom/player_finish.png");
    }
  }


  .control-block.desktop {
    .media-body {
      .artist {
        .karaoke-uztelecom-font-regular();
        color: @karaoke-uztelecom-color-gray;
      }

      .song {
        .karaoke-uztelecom-font-regular();
        color: @karaoke-uztelecom-color-white;
      }
    }

    .play-end .pb-icon {
      background-image: url('./uztelecom/player_finish.png');
    }

    .play-next .pb-icon {
      background-image: url('./uztelecom/player_next.png');
    }
  }

  @media @desktop {
    .wrapper {
      background: @karaoke-uztelecom-color-blue;
    }

    player-song-copyrights {
      color: @karaoke-uztelecom-color-gray;
    }

    .kar-text {
      color: @karaoke-uztelecom-color-gray;

      &.ready {
        // пропеваемый блок
        .karaokeBlock.speakingBlock {
          span[data-begin].speaking {
            color: @karaoke-uztelecom-color-white;
          }

          span[data-begin].speaking.finish {
            color: @karaoke-uztelecom-color-white;
          }
        }
      }
    }

    .progress-bar {
      border-top: 1px solid @karaoke-uztelecom-color-gray;
    }
  }

  player-button {
    .pb-icon {
      &.state-stop {
        background-image: url("./uztelecom/player_play.png");
      }

      &.state-pause {
        background-image: url("./uztelecom/player_play.png");
      }

      &.state-play {
        background-image: url("./uztelecom/player_stop.png");
      }

      &.state-load {
        background-image: url("./uztelecom/player_wait.png");
        animation: fa-spin 2s infinite linear;
      }
    }

    .pb-label {
      .karaoke-uztelecom-font-small();
      color: @karaoke-uztelecom-color-gray;
    }
  }

  player-button.sing .pb-icon {
    background-image: url("./uztelecom/player_sing.png");
  }

  player-button.record {
    .pb-icon {
      &.state-disabled {
        background-image: url("./uztelecom/recorder_disabled.png");
      }

      &.state-off {
        background-image: url("./uztelecom/recorder_disabled.png");
      }

      &.state-on {
        background-image: url("./uztelecom/recorder_enabled.png");
      }

      &.state-duet {
        background-image: url("./uztelecom/recorder_duet_enabled.png");
      }

      &.state-battle {
        background-image: url("./uztelecom/recorder_battle_enabled.png");
      }
    }
  }

  @media @desktop {
    player-button .pb-icon {

      &.state-stop {
        background-image: url("./uztelecom/player_play_desktop.png");
      }

      &.state-pause {
        background-image: url("./uztelecom/player_play_desktop.png");
      }

      &.state-play {
        background-image: url("./uztelecom/player_stop_desktop.png");
      }

      &.state-load {
        background-image: url("./uztelecom/player_wait.png");
      }
    }
  }

  @media @phone {
    player-song-title {
      display: block;
      margin-left: @karaoke-dim-page-padding-mobile;
      margin-right: @karaoke-dim-page-padding-mobile;
    }

    .kar-progress {
      margin-left: @karaoke-dim-page-padding-mobile;
      margin-right: @karaoke-dim-page-padding-mobile;
    }
  }
}
