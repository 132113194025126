@import "../../../../branding/uztelecom/app-uztelecom-defenition";

.disclaimer :global {
  background-color: @karaoke-uztelecom-color-blue;
  color: @karaoke-uztelecom-color-white;

  a {
    color: @karaoke-uztelecom-color-white;
    // reset
    text-decoration: none;
    font-weight: bold;
  }

  :first-child, .app-label:first-child {
    padding-top: @karaoke-dim-page-padding-mobile
  }

  .center-block {
    padding-bottom: @karaoke-dim-page-padding-mobile;
  }

  .app-label {
    .karaoke-uztelecom-font-regular();
    padding: 0 @karaoke-dim-page-padding-mobile @karaoke-dim-page-padding-mobile @karaoke-dim-page-padding-mobile;
    // reset
    text-shadow: none;
  }

  .button-subscribe {
    .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-yellow, @karaoke-uztelecom-color-dark);
    height: 40px;
    padding: 1px 20px 7px;
    width: 225px;
  }

}