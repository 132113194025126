@import "../../../../branding/uztelecom/app-uztelecom-defenition";
@import "MenuComponent";

.menu :global {
  background-color: @karaoke-uztelecom-color-white;
  color: @karaoke-uztelecom-color-blue;

  .app-header {
    .logo {
      display: none;
      .karaoke-uztelecom-font-bold();
      padding: 5px 42px;
    }

    .back-button a {
      margin-right: 4px;
      background-image: url("./uztelecom/ico_close.png");
      background-repeat: no-repeat;
      background-position: center center;

      @media @retina { background-image: url("./uztelecom/ico_close@2x.png");}
    }
  }

  .search {
    margin: 0 @karaoke-dim-page-padding-mobile;
  }

  .app-header-line {
    .karaoke-uztelecom-font-bold();

    padding: 8px @karaoke-dim-page-padding-mobile;

    a, .text {
      color: @karaoke-uztelecom-color-blue;
    }

    &:before {
      background-color: @karaoke-uztelecom-color-white;
    }

    &:after {
      border: 0;
    }

    .note {
      transition: transform linear 100ms;
      &.open, &.close {
        background-image: url("./uztelecom/ico_arrow.png");
      }

      &.close {
        opacity: 1;
        transform: rotate(90deg);
      }
    }

    .ink {
      background-color: @karaoke-uztelecom-color-blue;
    }
  }

  .item {
    .karaoke-uztelecom-font-regular();
    border-color: transparent;
    color: @karaoke-uztelecom-color-blue;
    text-shadow: none;

    span {
      margin: 12px 0;
    }
  }


  .app-header-line {
    .open {
      //content: data-uri('./images/ico_arrow_down.png');
      //.image-content('./images/ico_arrow_down.png');
      background-image: data-uri('./common/ico_arrow_down.png');
      //background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .close {
      //content: data-uri('./images/ico_arrow_up.png');
      //.image-content('./images/ico_arrow_up.png');
      background-image: data-uri('./common/ico_arrow_up.png');
      //background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  & {
    .section-main { .app-header-line-menu-color(@section-main-bg-color, @section-main-border-color, @section-main-hover-bg-color, @section-main-hover-border-color); }
    .section-catalog { .app-header-line-menu-color(@section-catalog-bg-color, @section-catalog-border-color, @section-catalog-hover-bg-color, @section-catalog-hover-border-color); }
    .section-popular { .app-header-line-menu-color(@section-popular-bg-color, @section-popular-border-color, @section-popular-hover-bg-color, @section-popular-hover-border-color); }
    .section-playlist { .app-header-line-menu-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
    .section-new { .app-header-line-menu-color(@section-new-bg-color, @section-new-border-color, @section-new-hover-bg-color, @section-new-hover-border-color); }
    .section-feedback { .app-header-line-menu-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }

    .app-header-line {
      &.section-main { .app-header-line-color(@section-main-bg-color, @section-main-border-color, @section-main-hover-bg-color, @section-main-hover-border-color); }
      &.section-catalog { .app-header-line-color(@section-catalog-bg-color, @section-catalog-border-color, @section-catalog-hover-bg-color, @section-catalog-hover-border-color); }
      &.section-popular { .app-header-line-color(@section-popular-bg-color, @section-popular-border-color, @section-popular-hover-bg-color, @section-popular-hover-border-color); }
      &.section-playlist { .app-header-line-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
      &.section-new { .app-header-line-color(@section-new-bg-color, @section-new-border-color, @section-new-hover-bg-color, @section-new-hover-border-color); }
      &.section-feedback { .app-header-line-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
    }
  }

  & {
    .app-header-line ~ .app-header-line {
      padding-top: 10px;
      margin-top: 0;

      &:before {
        position: absolute;
        content: '';
        border-top: 1px solid @karaoke-uztelecom-color-divider;
        width: 100%;
        width: calc(100% - 2 * 20px);
        transform: translateX(-50%);
        left: 50%;
      }

      // для данного оператора уже есть разделитель у языка
      &.section-main {
        &:before {
          display: none;
        }
      }
    }
  }
}