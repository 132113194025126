@import "../../../../branding/uztelecom/app-uztelecom-defenition";

.battle-status :global {

  input.text {
    color: @karaoke-text-color;
  }

  .btn {
    .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-white, @karaoke-uztelecom-color-blue, @karaoke-uztelecom-color-white);
  }
}