@import "../../../../../branding/uztelecom/app-uztelecom-defenition";

.song-item :global {

  background: transparent;
  border: 1px solid transparent;

  margin-top: -6px;
  @icon-size: 52px;

  /*@desktop {
    background: @karaoke-uztelecom-color-white;
    border: 1px solid @karaoke-uztelecom-color-white;
  }*/

  .icon-big() {
    width: @icon-size;
    height: @icon-size;
    background-size: @icon-size;
  }

  .play {
    // icon
    background-image: url('./uztelecom/ico_mic.png');
    //.play
    .icon-big();

    &.art {
      border-radius: @icon-size/2;
    }
  }

  .playlist-added {
    background-image: url('./uztelecom/ico_check.png');
  }

  .playlist-add {
    background-image: url('./uztelecom/ico_plus.png');
  }

  .play {
    margin: 0 12px 0 0;
  }

  .name, .author, .catalog {
    display: block;
    color: @karaoke-uztelecom-color-dark;
    margin-left: 64px; // icon
  }

  .catalog {color: @karaoke-uztelecom-color-blue;

  }
}

.song-item.ripple-effect .ink {
  background: @karaoke-primary-accent-color;
}