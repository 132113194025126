@import "../../../../branding/uztelecom/app-uztelecom-defenition";

.performance-response-selector :global {
  background-color: @karaoke-uztelecom-color-blue;
  color: @karaoke-uztelecom-color-white;

  .app-label {
    .karaoke-uztelecom-font-regular();
    padding: @karaoke-dim-page-padding-mobile @karaoke-dim-page-padding-mobile @karaoke-dim-page-padding-mobile 0;
    // reset
    text-shadow: none;
  }

  .button {
    .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-white, @karaoke-uztelecom-color-blue, @karaoke-uztelecom-color-white);
    min-width: unset;
  }
}