@import "../../../../branding/uztelecom/app-uztelecom-defenition";

.player-song-progress :global {
  .progress {
    background: @karaoke-uztelecom-color-gray-light;
    .progress-bar {
      background-color: @karaoke-uztelecom-color-blue;
    }
  }

  .progress-begin, .progress-end {
    .karaoke-uztelecom-font-small();
    color: @karaoke-uztelecom-color-dark;
  }
}