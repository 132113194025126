@import "../../../../branding/uztelecom/app-uztelecom-defenition";
@import "SearchComponent";

@icon-search: './uztelecom/search.png';
@offset-horizontal: 16px;
@offset-vertical: 18px;
@color-text: @karaoke-search-text-color;
@color-placeholder: @karaoke-search-placeholder-color;

.search :global {
  background: @karaoke-uztelecom-color-background;
  border: 1px solid @karaoke-uztelecom-color-border;

  .search-impl(@offset-vertical, @offset-horizontal, @color-text, @color-placeholder, @icon-search, 5px);
}

