@import "../../../../branding/uztelecom/app-uztelecom-defenition";
@import "./PageTitleComponent_tpl_v2_legacy";

@color-back: white;
@color-logo: #1E1E1E;
@color-text: black;
@color-text-accent: @color-logo;

.page-title :global {
  .page-title(@color-back, @color-logo, @color-text, @color-text-accent);

  .back-button a {
    background-image: url('./uztelecom/ico_back.png');
    @media @retina {
      background-image: url('./uztelecom/ico_back@2x.png');
    }
  }

  .menu-button a {
    background-image: url('./uztelecom/ico_menu.png');
    @media @retina {
      background-image: url('./uztelecom/ico_menu@2x.png');
    }
  }

  .profile-button a {
    background-image: url('./uztelecom/ico_profile.png');
    @media @retina {
      background-image: url('./uztelecom/ico_profile@2x.png');
    }
  }

  .auth-button a {
    background-image: url('./uztelecom/ico_auth.png');
    @media @retina {
      background-image: url('./uztelecom/ico_auth@2x.png');
    }
  }

  .search-button a {
    background-image: url('./uztelecom/ico_header_search.png');
    @media @retina {
      background-image: url('./uztelecom/ico_header_search@2x.png');
    }
  }

  .link-wrapper {
    width: 152px;
  }

  .logo {
    &:before {
      @logo-path: './uztelecom/logo.png';
      @logo-width: image-width(@logo-path);
      @logo-height: image-height(@logo-path);
      display: inline-block;
      content: "" !important; // patch against element-header
      background-image: data-uri(@logo-path);
      background-size: @logo-width @logo-height;
      width: @logo-width;
      height: @logo-height;
      font-weight: bold;
      margin-top: -8px;
    }

    /*&:after {
      margin-left: 1em;
      display: inline-block;
      font-family: "PT Sans", arial, serif;
      font-size: 12px;
      font-weight: normal;
    }*/

  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media @retina {
    .logo {
      &:before {
        background-image: url('./uztelecom/logo@2x.png');
      }
    }
  }
}
